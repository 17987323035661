import React from 'react';

import { BreadcrumbFragment } from '../../../graphql-types';
import { WLBreadcrumb } from '../../design-library';
import { BreadcrumbEntry } from '../../design-library/Breadcrumb';

interface ParentPage extends BreadcrumbFragment {
  parentPage?: ParentPage | null;
}

interface BreadcrumbAdapterProps {
  currentPage?: string | null;
  parent?: ParentPage | null;
}

const BreadcrumbAdapter: React.FC<BreadcrumbAdapterProps> = ({
  currentPage,
  parent,
}) => {
  const breadcrumbData = React.useMemo(() => {
    const entries: BreadcrumbEntry[] = [];
    if (!currentPage || !parent) {
      return [];
    }
    entries.push({ label: currentPage });
    let current: ParentPage | undefined | null = parent;
    while (current) {
      entries.unshift({
        label: current.breadcrumbTitle!,
        link: current.slug!,
      });
      current = current.parentPage;
    }
    return entries;
  }, [currentPage, parent]);

  return <WLBreadcrumb entries={breadcrumbData} />;
};

export default BreadcrumbAdapter;
