import { Center } from '@chakra-ui/react';
import React, { useState } from 'react';

export interface TableProps {
  setModalState: Function;
  onOpen: Function;
  stateValue: string;
}

const MobileTable: React.FC<TableProps> = ({
  setModalState,
  onOpen,
  stateValue,
}) => {
  const aOptions = [
    'Basic Benefits (including hospice care)',
    'Part B Coinsurance',
    'Part A Coinsurance',
    'Skilled Nursing Facility Coinsurance',
    'Foreign Travel Emergency',
    `Part B Excess Charges`,
    'Part B Deductible',
  ];

  const aPlans = [
    {
      name: 'Plan A',
      options: [true, true, true, false, false, false, false],
      isFor: [
        `Basic coverage`,
        `Lower monthly premiums`,
        `Paying for out-of-pocket expenses as you go`,
      ],
      howItWorks: `Plan A is a basic coverage plan from which the other Medicare supplement plans build upon.

      This plan helps cover out-of-pocket expenses that Medicare doesn't cover, such as Medicare Part A coinsurance and hospital costs, Medicare Part B coinsurance or copayment, first three pints of blood and Part A hospice care coinsurance or copayment.
      
      However, it does not cover the coinsurance for skilled nursing facility care, the Part A deductible ($1,632 in 2024), the Part B deductible ($240 in 2024), Part B excess charges or foreign travel emergencies - meaning you'll need to pay for these services out-of-pocket in the event you'll need them.`,
    },
    {
      name: 'Plan F*',
      options: [true, true, true, true, true, true, true],
      isFor: [
        `Paying upfront`,
        `Higher monthly premiums`,
        `No out-of-pocket surprises`,
        `Strong protection`,
      ],
      howItWorks: `The most comprehensive coverage option, Plan F helps you pay the Medicare Part A deductible ($1,632 in 2024), coinsurance and other costs incurred from Medicare Part A.

      It also pays the cost incurred from Medicare Part B, including the annual deductible.
      
      It covers the remaining Medicare approved expenses, so you won't need to pay anything.
      
      Plan F is available to those eligible for Medicare on or before 01/01/2020.
      `,
    },
    {
      name: 'Plan G',
      options: [true, true, true, true, true, true, false],
      isFor: [
        `Paying upfront`,
        `Higher monthly premiums`,
        `No out-of-pocket surprises`,
        `Strong protection`,
      ],
      howItWorks: `Our most popular plan, Plan G helps you pay the Medicare Part A deductible ($1,632 in 2024), coinsurance and other costs incurred from Medicare Part A.

      However, it does not help you pay all costs. You still need to pay the Medicare Part B deductible ($240 in 2024) before Plan G begins paying benefits.
      
      After the Medicare Part B deductible is met, it covers the remaining Medicare approved expenses.
      `,
    },
    {
      name: 'High-Ded G†',
      options: [true, true, true, true, true, true, false],
      isFor: [
        `Paying as you go`,
        `Low monthly premiums even it means there could be high out-of-pocket costs`,
        `Safety net" type coverage`,
      ],
      howItWorks: `Before High-Deductible Plan G begins paying benefits, you will need to pay Medicare-covered costs (deductibles, coinsurance and copayments) of $2,800 (in 2024).

      After you reach $2,800 the plan kicks in and helps you pay the remaining Medicare Part A deductible, coinsurance and other costs incurred from Medicare Part A.
      
      However, it does not help you pay the cost incurred from Medicare Part B, you will need to pay the Medicare Part B deductible ($240 in 2024).
      
      It covers the remaining Medicare approved expenses, so you won't need to pay anything else.
      `,
    },
    {
      name: 'Plan N',
      options: [true, true, true, true, true, false, false],
      isFor: [
        `Paying some upfront and some as you go`,
        `A mix of monthly premiums and copays`,
        `Occasional out-of-pocket expenses`,
        `Strong protection`,
      ],
      howItWorks: `Plan N helps you pay the Medicare Part A deductible ($1,632 in 2024) coinsurance and other costs incurred from Medicare Part A.

      However, it does not help you pay all costs incurred from Medicare Part B. You still need to pay the Medicare Part B deductible $240 (in 2024).
      
      After the $240 deductible is met, you will still pay a copay of up to $20 each time you go to the doctor, and a copay of up to $50 if you go to the emergency room.
      `,
    },
  ];

  if (stateValue === 'PA') {
    aPlans[5] = {
      name: 'Plan B',
      options: [true, true, true, false, false, false, false],
      isFor: [
        `Basic Coverage`,
        `Lower monthly premiums`,
        `Paying for out-of-pocket expenses such as copayments and coinsurance`,
      ],
      howItWorks: `Plan B provides basic coverage, which includes coverage of the Part A deductible [($1,632 in 2024)] and Part A coinsurance and hospital out-of-pocket expenses up to 365 days after Medicare benefits are used up. 
  
        However, it does not cover the coinsurance for skilled nursing facility care, the Part B deductible [($240 in 2024)], Part B excess charges or foreign travel emergencies – meaning you’ll need to pay for these services out-of-pocket in the event you’ll need them.
        `,
    }
  }

  if (stateValue === 'NJ') {
    aPlans[5] = {
      name: 'Plan C',
      options: [true, true, true, false, false, false, true],
      isFor: [
        `No out-of-pocket surprises`,
        `Strong protection`,
      ],
      howItWorks: `This plan provides coverage of the Part B deductible ($240 in 2024), in exchange for a higher monthly premium.  
  
      It also provides ample coverage (with exception to Part B excess charges), including coverage of the Part A deductible ($1,632 in 2024) and the Part B deductible ($240 in 2024), leaving no room for unexpected expenses. 

      Additionally, Plan C covers 80% of foreign travel emergencies. Emergency coverage exists for the first 60 days of travel outside of the country. 

      Plan C is available for those who are Medicare-eligible on or before Jan. 1, 2020. 
        `,
    }
    aPlans[6] = {
      name: 'Plan D',
      options: [true, true, true, false, false, false, false],
      isFor: [
        `Strong protection`,
        `Foreign travel emergency coverage `,
        `Cover Part A deductible`,
      ],
      howItWorks: `Plan D provides ample coverage, including coverage of the Part A deductible ($1,632 in 2024) and associated coinsurance and hospital costs.  
  
      Plan D does 80% of foreign travel emergencies
        `,
    }
  }
  const [colOne, setColOne] = useState(aPlans[0]);
  const [colTwo, setColTwo] = useState(aPlans[aPlans.length - 1]);

  const togglePlan = (col: number, plan: string) => {
    if (col === 1) {
      setColOne(aPlans[parseInt(plan)]);
    } else {
      setColTwo(aPlans[parseInt(plan)]);
    }
  };

  return (
    <>
      <table className="comparisonTable">
        <tr>
          <th style={{ paddingBottom: '48px' }}>
            <div className="select-menu">
              <select onChange={(e) => togglePlan(1, e.target.value)}>
                {aPlans.map((plan, pi) => {
                  return <option value={pi}>{plan.name}</option>;
                })}
              </select>
              <svg className="triangle" viewBox="0 0 10 8">
                <path
                  id="custom-triangle"
                  d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                  transform="translate(10 8) rotate(180)"
                />
              </svg>
            </div>
          </th>

          <th style={{ paddingBottom: '48px' }}>
            <div className="select-menu">
              <select onChange={(e) => togglePlan(2, e.target.value)}>
                {aPlans.map((plan, pi) => {
                  return (
                    <option value={pi} selected>
                      {plan.name}
                    </option>
                  );
                })}
              </select>
              <svg className="triangle" viewBox="0 0 10 8">
                <path
                  id="custom-triangle"
                  d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                  transform="translate(10 8) rotate(180)"
                />
              </svg>
            </div>
          </th>
        </tr>

        {aOptions.map((option, i) => (
          <tr>
            <td
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: i,
                  };
                });
                onOpen();
              }}
            >
              <span
                style={{
                  color: '#003366',
                  fontWeight: 'bold',
                  display: 'block',
                  marginBottom: '16px',
                }}
              >
                {option}
                {option === 'Part B Excess Charges' && stateValue === 'TX' && (
                  <sup>§</sup>
                )}
                {option === 'Part B Coinsurance' &&
                  colOne.name === 'Plan N' && <sup>‡</sup>}
              </span>
              <Center>
                <img
                  src={
                    colOne.options[i]
                      ? '/images/checkmark.svg'
                      : '/images/xmark.svg'
                  }
                  alt={colOne.options[i] ? 'Included' : 'Not included'}
                  width="20"
                  height="20"
                />
              </Center>
            </td>
            <td
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: i,
                  };
                });
                onOpen();
              }}
            >
              <span
                style={{
                  color: '#003366',
                  fontWeight: 'bold',
                  display: 'block',
                  marginBottom: '16px',
                }}
              >
                {option}
                {option === 'Part B Excess Charges' && stateValue === 'TX' && (
                  <sup>§</sup>
                )}
                {option === 'Part B Coinsurance' &&
                  colTwo.name === 'Plan N' && <sup>‡</sup>}
              </span>
              <Center>
                <img
                  src={
                    colTwo.options[i]
                      ? '/images/checkmark.svg'
                      : '/images/xmark.svg'
                  }
                  alt={colTwo.options[i] ? 'Included' : 'Not included'}
                  width="20"
                  height="20"
                />
              </Center>
            </td>
          </tr>
        ))}
      </table>
    </>
  );
};

export default MobileTable;
