import { HStack, VStack } from '@chakra-ui/react';
import * as React from 'react';

import { BenefitsListItemProps } from '../Benefits/BenefitsListItem';
import BenefitsListItem from '../Benefits/BenefitsListItem';

type Item = {
  svg?: React.ReactElement;
  heading: string;
  copy: string;
};
export interface BenefitsListItemAdapterProps {
  items: Item[];
}

const BenefitsListItemAdapter: React.FC<BenefitsListItemAdapterProps> = ({
  items,
}) => {
  if (!items) {
    return null;
  }
  return (
    <VStack
      width="full"
      align="flex-start"
      justifyContent="space-between"
      spacing={0}
      gap={{ base: 'auto', lg: '95px' }}
    >
      {items.map((item, idx) => {
        return (
          <BenefitsListItem
            key={`item-${idx + 1}`}
            item={item}
            direction={(idx + 1) % 2 === 0 ? 'row-reverse' : 'row'}
          />
        );
      })}
    </VStack>
  );
};

export default BenefitsListItemAdapter;
