import { Box, Stack, VStack } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import { WLH1, WLSelect, WLText } from '../../../design-library';
import { SelectVariant } from '../../../design-library/Forms/Select/Select';

interface HeroProps {
  handleStateOptions: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  stateValue: string;
  showPage: boolean;
}

const Hero: React.FC<HeroProps> = ({
  handleStateOptions,
  stateValue,
  showPage,
}) => {
  const result = useStaticQuery(graphql`
    query HeroQuery {
      file(name: { eq: "MedSup_Hero" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR)
        }
      }
    }
  `);

  if (!result) return null;

  const heroImage = getImage(result.file);

  return (
    <>
      <Stack
        direction={{ base: 'column' }}
        position="relative"
        mt={0}
        mb={showPage ? '56px' : 0}
        spacing={0}
      >
        <Box height={{ base: 'auto', tablet: '600px', lg: 'auto' }}>
          {heroImage && (
            <GatsbyImage
              image={heroImage}
              alt="A smiling elderly couple"
              style={{ height: '100%', width: 'auto' }}
            />
          )}
        </Box>
        <VStack
          justify="center"
          align={{ base: 'center', tablet: 'flex-start' }}
          textAlign={{ base: 'center', tablet: 'left' }}
          color="#767676"
          position={{ base: 'relative', tablet: 'absolute' }}
          zIndex={1}
          inset="0"
          width={{ base: 'auto', tablet: '50%' }}
          paddingLeft={{ base: 'unset', tablet: '10%' }}
          paddingRight={{ base: 'unset', tablet: '5%' }}
          bgGradient={{ base: 'linear(to-b,  white, #F4F1EF)', tablet: 'none' }}
        >
          <WLText
            mt={{ base: 6, tablet: 'unset' }}
            textTransform="uppercase"
            fontSize="sm"
            fontWeight={900}
            letterSpacing=".125em"
            style={{ wordSpacing: '.125em' }}
          >
            Coverage you can rely on
          </WLText>
          <WLH1 fontSize={{ base: '32px', lg: '56px' }} color="wl-blue.navy">
            Medicare Supplement Insurance
          </WLH1>
          <VStack
            position="relative"
            paddingBottom="56px"
            paddingX={{ base: 6, tablet: 'unset' }}
            align={{ base: 'center', tablet: 'flex-start' }}
            spacing={'24px'}
          >
            <WLText color="#3D3C3C" fontSize={{ base: '16px', lg: '18px' }}>
              Learn about the different Medicare Supplement insurance plans
              available so you can make the choice that’s right for you.
            </WLText>
            <WLSelect
              name="state"
              color="#003366"
              bg="white"
              style={{
                borderRadius: '99px',
                border: '2px solid #003366',
                height: '44px',
              }}
              variant={SelectVariant.FILLED}
              onChange={(e) => handleStateOptions(e)}
              placeholder={'Select your state'}
              value={stateValue}
              options={[
                // AS OF 2023-07-20: Alabama, Georgia, Illinois, Iowa, Louisiana, Michigan, Mississippi, South Carolina, Texas, West Virginia -NE
                { label: 'Alabama', value: 'AL' },
                // { label: 'Alaska', value: 'AK' },
                { label: 'Arizona', value: 'AZ' },
                { label: 'Arkansas', value: 'AR' },
                // { label: 'California', value: 'CA' },
                { label: 'Colorado', value: 'CO' },
                // { label: 'Connecticut', value: 'CT' },
                // { label: 'Delaware', value: 'DE' },
                { label: 'Florida', value: 'FL' },
                { label: 'Georgia', value: 'GA' },
                // { label: 'Hawaii', value: 'HI' },
                // { label: 'Idaho', value: 'ID' },
                { label: 'Illinois', value: 'IL' },
                { label: 'Indiana', value: 'IN' },
                { label: 'Iowa', value: 'IA' },
                // { label: 'Kansas', value: 'KS' },
                { label: 'Kentucky', value: 'KY' },
                { label: 'Louisiana', value: 'LA' },
                // { label: 'Maine', value: 'ME' },
                // { label: 'Maryland', value: 'MD' },
                // { label: 'Massachusetts', value: 'MA' },
                { label: 'Michigan', value: 'MI' },
                // { label: 'Minnesota', value: 'MN' },
                { label: 'Mississippi', value: 'MS' },
                // { label: 'Montana', value: 'MT' },
                // { label: 'Nevada', value: 'NV' },
                // { label: 'New Hampshire', value: 'NH' },
                { label: 'New Jersey', value: 'NJ' },
                // { label: 'New Mexico', value: 'NM' },
                // { label: 'New York', value: 'NY' },
                { label: 'North Carolina', value: 'NC' },
                // { label: 'North Dakota', value: 'ND' },
                { label: 'Ohio', value: 'OH' },
                { label: 'Oklahoma', value: 'OK' },
                { label: 'Oregon', value: 'OR' },
                { label: 'Pennsylvania', value: 'PA' },
                // { label: 'Rhode Island', value: 'RI' },
                { label: 'South Carolina', value: 'SC' },
                // { label: 'South Dakota', value: 'SD' },
                { label: 'Tennessee', value: 'TN' },
                { label: 'Texas', value: 'TX' },
                // { label: 'Utah', value: 'UT' },
                { label: 'Virginia', value: 'VA' },
                // { label: 'Vermont', value: 'VT' },
                // { label: 'Washington', value: 'WA' },
                { label: 'West Virginia', value: 'WV' },
                // { label: 'Wyoming', value: 'WY' },
              ]}
            />
            <WLText fontSize="12px" fontWeight={'bold'}>
              Product not available in all states.
            </WLText>
          </VStack>
        </VStack>
      </Stack>
    </>
  );
};

export default Hero;
