import { Box, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

export interface BenefitsListItemProps {
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse' | undefined;
  item: {
    svg?: React.ReactNode;
    heading: string | null | undefined;
    copy: string | null | undefined;
  };
}

const BenefitsListItem: React.FC<BenefitsListItemProps> = ({
  item,
  direction = 'column',
}) => {
  const { heading, copy, svg } = item;

  return (
    <Stack
      direction={{
        base: 'column',
        md: direction || 'row',
      }}
      width="full"
      padding="20px"
      spacing={0}
      alignItems="center"
      gap="24px"
    >
      <Box width={['250px', null, '200px']} height={['250px', null, '200px']}>
        {svg}
      </Box>
      <VStack
        spacing={0}
        justifyContent={{ base: 'flex-start', md: 'flex-end' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        flex="1 1 75%"
        gap="24px"
        textAlign={{ base: 'center', md: 'left' }}
      >
        <Heading
          flex="1 1 50%"
          fontSize={['24px', null, '32px']}
          color="wl-blue.navy"
          fontWeight={'bold'}
          style={{ marginBottom: '0px' }}
        >
          {heading}
        </Heading>
        <Text
          flex={{ base: '1 1 auto', md: '0 1 50%' }}
          color="wl-blue.navy"
          fontSize={[null, null, '18px']}
          lineHeight="24px"
        >
          {copy}
        </Text>
      </VStack>
    </Stack>
  );
};
export default BenefitsListItem;
