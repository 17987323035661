import { Table as ChakraTable, TableContainer } from '@chakra-ui/react';
import * as React from 'react';

export interface TableProps {
  setModalState: Function;
  onOpen: Function;
  stateValue: string;
}

const DesktopTable: React.FC<TableProps> = ({
  setModalState,
  onOpen,
  stateValue,
}) => {
  const [headerStyle, setHeaderStyle] = React.useState({});

  const tableStyles = {
    table: {
      border: 'none',
      color: 'wl-blue.navy',
      fontFamily: 'Inter',
    },
    col: {
      borderRight: '1px solid #E0E0E0',
    },

    _first: {
      tr: {
        border: 'none',
        _first: {
          height: '42px',
          td: {
            background: 'white',
            borderTop: 'none',
            height: '42px',
          },
          th: {
            borderTop: '1px solid #E0E0E0',
            height: '42px',
          },
        },
      },
    },
    thead: {
      tr: {
        _odd: {
          th: {
            background: '#F4F1EF',
            border: 'none',
            borderTop: '1px',
            borderBottom: '1px',
            borderColor: '#E0E0E0',
          },
        },
        ':nth-child(2), :nth-child(3)': {
          th: {
            background: 'white',
            border: 'none',
          },
        },
        th: {
          border: 'none',
          _first: {
            border: 'none',
            borderRight: '1px solid #E0E0E0',
            background: 'white',
          },
        },
      },
    },
    tr: {
      borderTop: '1px',
      borderBottom: '1px',
      borderColor: '#E0E0E0',
      _odd: {
        background: '#F4F1EF',
      },
      _even: {
        background: 'white',
      },
    },

    'tr, td': {
      height: '131px',
    },
    td: {
      border: 'none',
      _notLast: {
        border: 'none',
      },
    },
    th: {
      background: 'none',
      fontFamily: 'Inter',
      fontWeight: 'bold',
      color: 'wl-blue.navy',
      borderBottom: 'none',
      width: '100px',
    },
  };

  return (
    <>
      <TableContainer width="full">
        <ChakraTable variant="unstyled" width="full" __css={tableStyles}>
          <colgroup>
            <col />
          </colgroup>
          <colgroup span={2}></colgroup>
          <colgroup span={2}></colgroup>
          <tr>
            <td rowSpan={2}></td>
            <th colSpan={stateValue === 'NJ' ? 7 : stateValue === 'PA' ? 6 : 5}>
              Plan
            </th>
          </tr>
          <tr>
            <th scope="col">A</th>
            {stateValue === 'NJ' && <th scope="col">C</th>}
            <th scope="col">
              F <sup>*</sup>
            </th>
            <th scope="col">G</th>
            <th scope="col">
              High-Deductible G <sup>†</sup>
            </th>
            <th scope="col">N</th>
            {stateValue === 'PA' && <th scope="col">B</th>}
            {stateValue === 'NJ' && <th scope="col">D</th>}
          </tr>
          <tr>
            <th
              scope="row"
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: 0,
                  };
                });
                onOpen();
              }}
              style={headerStyle}
              onMouseEnter={() => setHeaderStyle({ cursor: 'pointer' })}
              onMouseLeave={() => setHeaderStyle({ cursor: 'none' })}
            >
              Basic Benefits (including hospice care)
            </th>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'PA' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
          </tr>
          <tr>
            <th
              scope="row"
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: 1,
                  };
                });
                onOpen();
              }}
              style={headerStyle}
              onMouseEnter={() => setHeaderStyle({ cursor: 'pointer' })}
              onMouseLeave={() => setHeaderStyle({ cursor: 'none' })}
            >
              Part B Coinsurance
            </th>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td style={{ position: 'relative' }}>
              <div>
                <img
                  src="/images/checkmark.svg"
                  alt="Included"
                  width="20"
                  height="20"
                  style={{
                    display: 'inline-block',
                    margin: '0 auto',
                  }}
                />{' '}
                <sup>‡</sup>
              </div>
            </td>
            {stateValue === 'PA' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
          </tr>
          <tr>
            <th
              scope="row"
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: 2,
                  };
                });
                onOpen();
              }}
              style={headerStyle}
              onMouseEnter={() => setHeaderStyle({ cursor: 'pointer' })}
              onMouseLeave={() => setHeaderStyle({ cursor: 'none' })}
            >
              Part A Coinsurance
            </th>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'PA' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
          </tr>
          <tr>
            <th
              scope="row"
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: 3,
                  };
                });
                onOpen();
              }}
              style={headerStyle}
              onMouseEnter={() => setHeaderStyle({ cursor: 'pointer' })}
              onMouseLeave={() => setHeaderStyle({ cursor: 'none' })}
            >
              Skilled Nursing Facility Coinsurance
            </th>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'PA' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
          </tr>
          <tr>
            <th
              scope="row"
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: 4,
                  };
                });
                onOpen();
              }}
              style={headerStyle}
              onMouseEnter={() => setHeaderStyle({ cursor: 'pointer' })}
              onMouseLeave={() => setHeaderStyle({ cursor: 'none' })}
            >
              Foreign Travel Emergency
            </th>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'PA' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
          </tr>
          <tr>
            <th
              scope="row"
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: 5,
                  };
                });
                onOpen();
              }}
              style={headerStyle}
              onMouseEnter={() => setHeaderStyle({ cursor: 'pointer' })}
              onMouseLeave={() => setHeaderStyle({ cursor: 'none' })}
            >
              {`Part B Excess Charges`}
              {stateValue === 'TX' && <sup>§</sup>}
            </th>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'PA' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
          </tr>
          <tr>
            <th
              scope="row"
              onClick={() => {
                setModalState((prevState) => {
                  return {
                    ...prevState,
                    activIndex: 6,
                  };
                });
                onOpen();
              }}
              style={headerStyle}
              onMouseEnter={() => setHeaderStyle({ cursor: 'pointer' })}
              onMouseLeave={() => setHeaderStyle({ cursor: 'none' })}
            >
              Part B Deductible
            </th>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/checkmark.svg"
                    alt="Included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            <td>
              <img
                src="/images/checkmark.svg"
                alt="Included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            <td>
              <img
                src="/images/xmark.svg"
                alt="Not included"
                width="20"
                height="20"
                style={{ margin: '0 auto' }}
              />
            </td>
            {stateValue === 'PA' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
            {stateValue === 'NJ' && (
              <>
                <td>
                  <img
                    src="/images/xmark.svg"
                    alt="Not included"
                    width="20"
                    height="20"
                    style={{ margin: '0 auto' }}
                  />
                </td>
              </>
            )}
          </tr>
        </ChakraTable>
      </TableContainer>
    </>
  );
};

export default DesktopTable;
