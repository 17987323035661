import { Heading, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import PillTabs from '../../MedicareSupplement/Tabs/Tabs';

interface PillTabsAdapterData {
  id?: string | null | undefined;
  heading: string;
  visible: boolean;
  preferences: {
    text: string;
  }[];
  subHeading: string;
  text: string[];
  name?: string;
  tabLabel: string;
}

interface PillTabsAdapterProps {
  tabs: PillTabsAdapterData[];
}

type TabProps = React.ComponentProps<typeof PillTabs>;
type TabData = ArrayElement<TabProps['tabs']>;
const PillTabsAdapter: React.FC<PillTabsAdapterProps> = ({ tabs }) => {
  const adapterTabs = React.useMemo(() => {
    if (!tabs) {
      return [];
    }
    return tabs.reduce((acc, tab) => {
      if (tab) {
        acc.push({
          tabLabel: tab.tabLabel,
          visible: tab.visible,
          items: (
            <Stack direction={{ base: 'column', md: 'row' }} spacing="48px">
              <>
                <VStack
                  width="full"
                  textAlign={{ base: 'center', md: 'left' }}
                  alignItems={{ base: 'center', md: 'flex-start' }}
                >
                  {tab.heading && (
                    <Heading
                      fontSize="24px"
                      color="wl-blue.navy"
                      textAlign={{ base: 'center', md: 'left' }}
                    >
                      {tab.heading}
                    </Heading>
                  )}
                  {tab.preferences.length &&
                    tab.preferences.map((pref, idx) => {
                      return (
                        <>
                          <Text
                            color="#3D3C3C"
                            fontSize={{ base: '16px', lg: '18px' }}
                            textAlign={{ base: 'center', md: 'left' }}
                          >
                            {pref.text}
                          </Text>
                          {idx < tab.preferences.length - 1 && (
                            <hr style={{ width: '100%' }} />
                          )}
                        </>
                      );
                    })}
                </VStack>
                <VStack
                  width="full"
                  textAlign={{ base: 'center', md: 'left' }}
                  alignItems={{ base: 'center', md: 'flex-start' }}
                >
                  {tab.subHeading && (
                    <Heading fontSize="24px" color="wl-blue.navy">
                      {tab.subHeading}
                    </Heading>
                  )}
                  {tab.text &&
                    tab.text.map((text, idx) => {
                      return (
                        <Text
                          color="#3D3C3C"
                          fontSize={{ base: '16px', lg: '18px' }}
                        >
                          {text}
                        </Text>
                      );
                    })}
                </VStack>
              </>
            </Stack>
          ),
        });
      }
      return acc;
    }, [] as TabData[]);
  }, [tabs]);
  if (!adapterTabs || adapterTabs.length === 0) {
    return null;
  }
  return <PillTabs tabs={adapterTabs} />;
};

export default PillTabsAdapter;
