import { Box, BoxProps, Heading, Stack, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

import { WLHeadlineDividerV2 } from '../../../design-library';

export interface SectionProps {
  children?: React.ReactNode | React.ReactNode[];
  heading?: React.ReactNode | React.ReactNode[] | string | null | undefined;
  overline?: string;
  copy?: React.ReactNode | React.ReactNode[] | string | null | undefined;
  background?: BoxProps['background'];
  style?: React.CSSProperties;
}

const Section: React.FC<SectionProps> = ({
  children,
  background = 'white',
  heading,
  overline = '',
  style,
  copy,
}) => {
  return (
    <VStack
      as="section"
      width="full"
      justifyContent="center"
      alignItems="center"
      bg={background}
      textAlign="center"
      paddingX="24px"
      style={style}
      spacing={'80px'}
    >
      {heading && (
        <Stack spacing={0} direction="column">
          <WLHeadlineDividerV2 as="p">{overline}</WLHeadlineDividerV2>

          {heading &&
            (React.isValidElement(heading) ? (
              <>{heading}</>
            ) : (
              <Heading
                as="h2"
                fontSize={{ base: '32px', lg: '56px' }}
                color="wl-blue.navy"
              >
                {heading}
              </Heading>
            ))}
          {copy && React.isValidElement(copy) ? (
            <>{copy}</>
          ) : (
            copy && (
              <Text color="#3D3C3C" fontSize={{ base: '16px', lg: '18px' }}>
                {copy}
              </Text>
            )
          )}
        </Stack>
      )}

      {children && (
        <VStack spacing={0} justifyContent="center" align="center" width="full">
          {React.Children.toArray(children).map((child, idx: number) => {
            return React.cloneElement(child as React.ReactElement<any>, {});
          })}
        </VStack>
      )}
    </VStack>
  );
};

export default Section;
