import * as React from 'react';

type Item = {
  heading: string;
  copy: string;
};
export interface ListProps {
  items: Item[];
  resourceName: string;
  itemComponent: any;
}

const List: React.FC<ListProps> = ({
  items,
  resourceName,
  itemComponent: ItemComponent,
}) => {
  return (
    <>
      <ItemComponent key={`${resourceName}-1`} {...{ [resourceName]: items }} />
    </>
  );
};

export default List;
