import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';

interface TableAdapterProps {
  stateValue: string;
}

const TableAdapter: React.FC<TableAdapterProps> = ({ stateValue }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isDesktop, setDesktop] = React.useState(
    typeof window !== `undefined` ? window.innerWidth >= 769 : null,
  );

  const updateMedia = () => {
    setDesktop(typeof window !== `undefined` ? window.innerWidth >= 769 : null);
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const [modalState, setModalState] = React.useState({
    activIndex: 0,
    modals: [
      {
        title: 'Basic Benefits',
        text: [
          `Medicare Part A coinsurance (hospitalization room and board, general nursing, miscellaneous services and supplies that Medicare does not pay for; also pays all of these expenses for 365 days after Medicare coverage ends)`,
          `Medicare Part B coinsurance (physician's services, inpatient and outpatient medical and surgical services and supplies, physical and speech therapy, diagnostic tests and durable medical equipment)`,
          `Hospice care coinsurance when patient meets Medicare's hospice requirements.`,
          `First three pints of blood each year.`,
        ],
      },
      {
        title: 'Part B Coinsurance',
        text: `Physician's services, inpatient and outpatient medical and surgical services and supplies, physical and speech therapy, diagnostic tests and durable medical equipment.`,
      },
      {
        title: 'Part A Coinsurance',
        text: `Hospitalization room and board, general nursing, miscellaneous services and supplies that Medicare does not pay for; also pays all of these expenses for 365 days after Medicare coverage ends.`,
      },
      {
        title: 'Skilled Nursing Facility Coinsurance',
        text: 'Pays coinsurance (up to $204 per day in 2024) for days 21-100 for a Medicare approved stay in a skilled nursing facility. Medicare pays all charges in days 1-20.',
      },
      {
        title: 'Foreign Travel Emergency',
        text: `Medically necessary emergency care services, beginning during the first 60 days of each trip outside the USA. You pay a $250 calendar year deductible, then the plan pays 80% of charges up to a lifetime maximum of $50,000.`,
      },
      {
        title: 'Medicare Part B Excess Charge',
        text: `The plan pays all charges over the amount that Medicare pays for a given service, which can be up to 15% more. Those extra charges would otherwise be billed to you. Some states prohibit doctors that take Medicare patients from charging more than that rate.`,
      },
      {
        title: 'Medicare Part B Deductible',
        text: `Pays the first $240 (in 2024) of each calendar year for Medicare-approved charges for physicians' services, inpatient and outpatient medical and surgical services and supplies, physical and speech therapy, diagnostic tests and durable medical equipment. If your plan does not have this benefit, you would need to pay the deductible out of pocket.`,
      },
    ],
  });

  const getText = () => {
    const { text } = modalState.modals[modalState.activIndex];
    return Array.isArray(text) ? (
      <Box>
        <ul style={{ listStyle: 'none' }}>
          {text.map((itemText: string, idx) => (
            <li style={{ marginBottom: '24px' }}>{`${
              idx + 1
            }. ${itemText}`}</li>
          ))}
        </ul>
      </Box>
    ) : (
      text
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {modalState.modals[modalState.activIndex].title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={'0px 24px 24px 24px'}>{getText()}</ModalBody>
        </ModalContent>
      </Modal>

      {typeof window !== 'undefined' ? (
        isDesktop ? (
          <DesktopTable
            setModalState={setModalState}
            onOpen={onOpen}
            stateValue={stateValue}
          />
        ) : (
          <MobileTable
            setModalState={setModalState}
            onOpen={onOpen}
            stateValue={stateValue}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default TableAdapter;
