import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';

export interface PillTabData {
  id?: string | null | undefined;
  items?: React.ReactElement | React.ReactElement[];
  name?: string;
  tabLabel: string;
  visible: boolean;
}

interface PillTabsProps {
  tabs: PillTabData[];
}

const PillTabs: React.FC<PillTabsProps> = ({ tabs }) => {
  return (
    <Box w="auto" mb="24px">
      <Tabs
        align="center"
        variant="soft-rounded"
        colorScheme="blue"
        isLazy
        lazyBehavior="unmount"
        minWidth="316px"
      >
        <TabList
          position="relative"
          bg="#D2E6F8"
          borderRadius="9999px"
          justifyContent="space-between"
          height="44px"
          width={{ base: '312px', sm: '320px', md: 'auto' }}
          maxWidth={'420px'}
          marginBottom="56px"
        >
          {tabs.map((tab, i) => {
            return (
              tab.visible && (
                <Tab
                  key={i}
                  fontSize={{ base: '14px', sm: '15px' }}
                  border="2px solid transparent"
                  bg="transparent"
                  zIndex={2}
                  _selected={{
                    borderColor: '#00549F',
                    color: 'white',
                    bg: '#00549F',
                    outlineStyle: 'none',
                    pointerEvents: 'none',
                  }}
                  color="#00549F"
                  _hover={{
                    bg: 'rgba(255,255,255,.5)',
                    borderColor: 'rgba(0,84,159,.25)',
                  }}
                  _active={{ outlineStyle: 'none' }}
                  _focus={{ outlineStyle: 'none', border: '2px solid #00549F' }}
                >
                  {tab.tabLabel}
                </Tab>
              )
            );
          })}
          <TabIndicator
            height="44px"
            opacity="75%"
            borderRadius="99px"
            borderColor="#00549F"
            color="white"
            bg="#00549F"
          />
        </TabList>
        <TabPanels m="0 auto">
          {tabs.map((tab, i) => {
            return tab.visible && <TabPanel>{<>{tab.items}</>}</TabPanel>;
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default PillTabs;
