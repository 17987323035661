import { Box, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';

import { WLButton, WLInput } from '../../design-library';
import { handleInputChange, handlePageNavigation } from './Forms.util';

interface FindACardFormProps {
  placeholder?: string;
  slug: string;
}

const FindACardForm: React.FC<FindACardFormProps> = ({ placeholder, slug }) => {
  const styles = useMultiStyleConfig('PromoCard', {});
  const [errors, setErrors] = React.useState(false);

  const breakpoint = useBreakpointValue({
    base: 'base',
    lg: 'lg',
  });

  return (
    <>
      {errors && (
        <Box role="alert" aria-live={'polite'} as="span" color="red">
          {`Please provide a valid Zip Code ${
            slug === '/find/rep' ? 'or Rep Name' : ''
          }`}
        </Box>
      )}
      <WLInput
        aria-required={true}
        required
        placeholder={placeholder}
        name="zipInput"
        onChange={handleInputChange}
        style={{
          border: errors ? '2px solid red' : 'unset',
        }}
      />
      <WLButton
        aria-label="Search Button"
        sx={styles.cardSearchButton}
        onClick={(e) => {
          setErrors(handlePageNavigation(e, slug, 'searchFields'));
        }}
        style={{
          height: breakpoint === 'base' ? '44px' : '56px',
        }}
      >
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#fff"
            d="M62.83,57.17L44.52,38.86c2.89-4.03,4.6-8.96,4.6-14.3C49.12,11,38.12,0,24.56,0C11,0,0,11,0,24.56
        s11,24.56,24.56,24.56c5.34,0,10.27-1.71,14.3-4.6l18.31,18.31C57.95,63.61,58.98,64,60,64c1.02,0,2.05-0.39,2.83-1.17
        C64.39,61.27,64.39,58.73,62.83,57.17z M24.56,41.12C15.43,41.12,8,33.69,8,24.56S15.43,8,24.56,8s16.56,7.43,16.56,16.56
        S33.69,41.12,24.56,41.12z"
          />
        </svg>
      </WLButton>
    </>
  );
};

export default FindACardForm;
