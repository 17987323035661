import { navigate } from 'gatsby';

import { isBrowser } from '../../utils/browser.utils';

export const loadFormData = (dataKey: string) => {
  if (!isBrowser()) {
    return undefined;
  }
  const storedData = window.sessionStorage.getItem(dataKey);

  if (storedData) {
    try {
      return JSON.parse(storedData);
    } catch (e) {
      return storedData;
    }
  }
};

export const setFormData = (dataKey: string, dataValue: any) => {
  if (!isBrowser()) {
    return;
  }
  window.sessionStorage.setItem(dataKey, JSON.stringify(dataValue));
};

export const clearFormData = (dataKey: string) => {
  if (!isBrowser()) {
    return;
  }
  window.sessionStorage.removeItem(dataKey);
};

export const handleInputChange = (e: any) => {
  sessionStorage.setItem(
    'searchFields',
    JSON.stringify({
      searchByOverride: isNaN(parseInt(e.target.value)) ? 'name' : 'zipCode',
      searchByValueOverride: e.target.value,
    }),
  );
};

export const handlePageNavigation = (
  e: React.SyntheticEvent,
  slug: string,
  sessionField: string,
) => {
  e.stopPropagation();

  const data = loadFormData(sessionField);

  if (data) {
    const val = data.searchByValueOverride;

    if (val) {
      navigate(slug);
    } else {
      return true;
    }
  } else {
    return true;
  }

  return false;
};
