import {
  Box,
  Heading,
  Text,
  useBreakpointValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { FindACardForm } from '../Forms';
interface FindRepProps {
  content?: string;
}
const FindARep: React.FC<FindRepProps> = ({ content }) => {
  const breakpoint = useBreakpointValue({ base: 'xs', sm: 'sm', lg: 'lg' });
  const styles = useMultiStyleConfig('PromoCard', {});

  const cardColor = '#B5D5F4';

  return (
    // Card
    <Box
      display="flex"
      flexDirection={{ base: 'column', lg: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      w={{ base: 'auto', lg: '100%' }}
      h={{ base: 'auto', lg: '420px' }}
      maxW={{ base: '552px', lg: '100%' }}
      padding={{
        base: '40px 16px 56px 16px',
        lg: '56px 40px',
      }}
      position={{ base: 'unset', lg: 'relative' }}
      bg={cardColor}
      borderRadius={{ base: '16px', lg: 'none' }}
      transition="all ease .3s"
      m="0 auto"
    >
      {/* Col 1 */}
      <Box flex="1 1 0px">
        <Box
          display="flex"
          flexDir={{ base: 'column', lg: 'row-reverse' }}
          justifyContent={{ base: 'unset', lg: 'space-evenly' }}
        >
          <Box display="flex" flexDir={{ base: 'column', lg: 'row' }}>
            <Text
              fontSize="14px"
              fontWeight="bold"
              lineHeight="20px"
              textAlign="center"
              color={{ base: '#00549F', lg: '#6C99C4' }}
              textTransform="uppercase"
              letterSpacing="0.125em"
              m={{ base: '0px 0px 44px 0px', lg: '0px' }}
              sx={{ wordSpacing: '0.125em' }}
              display={{ base: 'unset', lg: 'none' }}
            >
              connect with woodmenlife
            </Text>
            {/* Heading Wrapper */}

            {/* Content*/}
            <Box
              position="relative"
              m={{ base: '64px 0px 40px 0px', lg: '80px 0px 40px 0px' }}
              transition="all ease .3s"
              left={{ base: 'unset', lg: '-110px' }}
              bottom={{ base: 'unset', lg: '-10px' }}
            >
              {/* Thumbnail Shadow */}
              <Box
                position="absolute"
                top={{ base: '-66px', lg: '-96px' }}
                right="0"
                left="0"
                w={{ base: '80px', lg: '110px' }}
                h={{ base: '80px', lg: '110px' }}
                borderRadius="100%"
                margin="0 auto"
                bg="#00549F"
                filter="drop-shadow(0 4px 8px rgba(0, 0, 0, .3))"
                transition="all ease .3s"
              ></Box>
              {/* Thumbnail */}
              <Box
                position="absolute"
                top={{ base: '-96px', lg: '-116px' }}
                right="0"
                left="0"
                overflow="hidden"
                m="0 auto"
                transition="all ease .3s"
                w={{ base: '80px', lg: '110px' }}
                h={{ base: '110px', lg: '130px' }}
                borderRadius={{
                  base: '0 0 40px 40px',
                  lg: '0 0 55px 55px',
                }}
              >
                {/* Circle */}
                <Box
                  as="div"
                  position="absolute"
                  bottom={0}
                  bg="#00549F"
                  transition="all ease .3s"
                  w={{ base: '80px', lg: '110px' }}
                  h={{ base: '80px', lg: '110px' }}
                  borderRadius={{ base: '40px', lg: '55px' }}
                ></Box>
                {/* Thumbnail mask */}
                <Box
                  as="div"
                  position="absolute"
                  overflow="hidden"
                  bottom={{ base: '-23px', lg: '-11px' }}
                  transition="all ease .3s"
                  w={{ base: '80px', lg: '110px' }}
                  h={{ base: '110px', lg: '130px' }}
                  borderRadius={{ base: '', lg: '' }}
                >
                  {/* Image Component */}
                  <StaticImage
                    src="../../../../static/images/rep.png"
                    alt="A male WoodmenLife Representative smiling"
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                    loading="lazy"
                    placeholder="blurred"
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            w="auto"
            padding="24px"
            borderRadius="16px"
            bg="transparent"
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Text
              fontSize="14px"
              fontWeight="bold"
              lineHeight="20px"
              color="#00549F"
              textTransform="uppercase"
              letterSpacing="0.125em"
              m={{ base: '0px 0px 44px 0px', lg: '0px' }}
              sx={{ wordSpacing: '0.125em' }}
              display={{ base: 'none', lg: 'unset' }}
            >
              connect with woodmenlife
            </Text>
            <Heading
              as="h3"
              m="0 0 8px 0"
              color="#003366"
              fontSize={{ base: '32px', lg: '44px', xl: '56px' }}
              letterSpacing="-.035em"
              textTransform="capitalize"
              transition="all ease .3s"
              sx={{ wordSpacing: '0.075em' }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              Find a Local <br />
              Representative
            </Heading>
          </Box>
        </Box>
      </Box>
      <Box flex="1 1 0px">
        <Text
          textAlign={{ base: 'center', lg: 'left' }}
          lineHeight="1.5rem"
          color="#003366"
        >
          {content
            ? content
            : `Our Representatives can help you choose a life insurance or retirement
          product that best meets your specific needs and budget.`}
        </Text>
        <Box
          sx={styles.cardSearchContainer}
          maxW="480px"
          m={{ base: '0 auto', lg: 'unset' }}
        >
          <FindACardForm
            placeholder={
              breakpoint === 'xs'
                ? 'ZIP Code or Name'
                : 'Search by ZIP Code or Rep Name'
            }
            slug="/find/rep"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FindARep;
